html{
  font-size: 17.5px;
  scroll-behavior: smooth;
}

body {
  margin: 0 !important;
  padding: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box !important;
  white-space: normal !important; /* Or use appropriate CSS to handle whitespace */

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

i{
  color: gold;
}

.gold_color{
  color: gold;
}

.green{
  background-color: #1da424;
}

.Cgreen{
   color: #1da424;
}

.Cred{
  color: #ff0000;
}


.hero{
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("../public/images/workman.jpg");
  background-size:cover;
  background-repeat: no-repeat;
  background-position:center;
}

.navBar{
 display: none !important;
}

.formBox_1 {
  background: #fff;
  border-radius: 0 0 4px 4px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
  transition: all 0.5s;
}

.info{
  display: none;
}

ul li a{
  color: #1da424 !important;
}

.white_box{
  background: #fff;
  border-radius: 0 0 4px 4px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
}


.btn_box{
transition: background-color 1s;
}

.btn_box:active{
  cursor: pointer;
  background-color: #ddd;
  color:#ff0000 !important;
}

.image-container {
  width: 300px; /* Parent container size */
  height: 300px; /* Set a fixed height */
  overflow: hidden; /* Hide any overflow if the image is larger than the container */
}

.image-container img {
  width: 100%; /* Make the image fit the width of the container */
  height: 100%; /* Make the image fit the height of the container */
  object-fit: cover; /* Ensures the image covers the entire container while maintaining its aspect ratio */
}

nav ul a:active{
    color: #ff0000 !important;
}

/* Media query for nav min-width: 768px */
@media only screen and (min-width: 768px){
 
.navBar{
  display: block !important;
 }

 .uptop {
  margin-top: 0px !important;
 }

 
.info{
  display: inline-block;
}

.forma form{
  width: 50% !important;
  margin: auto !important;
  padding: 20px 50px 20px 50px !important;
}


.uptop{
  padding: 200px 0px 70px 0px !important;
}

.uptop button{
  padding: 10px 15px 10px 15px !important;
}

.news_box{
  margin-top: 30px !important;
}

.favicon{
  width: 10% !important;
}

.L_form{
  width: 50% !important;
  margin: auto ;
}

.Abox{
  margin-top: 150px;
}

.cont-pad{
  padding-top: 140px !important;
}

.para{
  margin-top: 25px !important;
}

.para_1{
  margin-bottom: 50px !important;
}

.v_center{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 180px;
}


}

  /* Your styles for laptop screens and above */
  @media only screen and (min-width: 1024px) {
  .image-container {
    width: 300px; /* Parent container size */
    height: 300px; /* Set a fixed height */
    overflow: hidden; /* Hide any overflow if the image is larger than the container */
  }
  
  .image-container img {
    width: 100%; /* Make the image fit the width of the container */
    height: 100%; /* Make the image fit the height of the container */
    object-fit: cover; /* Ensures the image covers the entire container while maintaining its aspect ratio */
  }
  
  .image-container-one {
    width: 330px; /* Parent container size */
    height: 300px; /* Set a fixed height */
    overflow: hidden; /* Hide any overflow if the image is larger than the container */
    margin: auto;
  }
  
  .image-container-one img {
    width: 100%; /* Make the image fit the width of the container */
    height: 100%; /* Make the image fit the height of the container */
    object-fit: cover; /* Ensures the image covers the entire container while maintaining its aspect ratio */
  }
  
}



/* Media query for nav min-width: 1440px */






